export const DayMilestones = [20, 40, 60, 80, 100, 120, 140, 160, 180, 2000] as const;
export type DayMilestone = (typeof DayMilestones)[number];

export const dayMilestoneColours: Record<DayMilestone, [string, string]> = {
  20: ['#c3db9e', '#a6c96e'],
  40: ['#6f8cb3', '#325b92'],
  60: ['#8c84a5', '#473a6f'],
  80: ['#da9999', '#c56666'],
  100: ['#98abff', '#758fff'],
  120: ['#7ee4c3', '#28d29a'],
  140: ['#e89368', '#e27844'],
  160: ['#ec8d91', '#df4148'],
  180: ['#9195e3', '#474ed1'],
  2000: ['#ecae51', '#e99a26'],
};

export const daysAchievementOpts = (days: DayMilestone) => ({
  achievementText: `For achieving ${days.toLocaleString()} days of reading on Sparx`,
  achievementNumber: days,
  colours: dayMilestoneColours[days] ?? dayMilestoneColours[2000],
});

export const getMilestonesAchieved = (before: number, after: number) => {
  const achieved: DayMilestone[] = [];
  for (const milestone of DayMilestones) {
    if (before < milestone && after >= milestone) {
      achieved.push(milestone);
    }
  }
  return achieved;
};
