import { RpcError } from '@protobuf-ts/runtime-rpc';
import {
  ListUploadedResultsResponse,
  UploadedAssessmentResult,
} from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { assessmentsSittingClient, getSchoolIdFromSession } from 'api/auth';

const sittingUploadedResultsQuery = (assessmentName: string, studentNames: string[]) => ({
  queryKey: ['assessments', assessmentName, 'uploaded', studentNames.sort()],
  queryFn: async () =>
    assessmentsSittingClient.listUploadedResults({
      schoolName: `schools/${await getSchoolIdFromSession()}`,
      assessmentName,
      studentNames,
    }).response,
});

export const useSittingUploadedResults = <TData = ListUploadedResultsResponse>(
  assessmentName: string,
  studentNames: string[],
  opts?: UseQueryOptions<ListUploadedResultsResponse, RpcError, TData>,
) =>
  useQuery({
    ...sittingUploadedResultsQuery(assessmentName, studentNames),
    ...opts,
  });

export const useSittingUploadedResultsMap = (
  assessmentName: string,
  studentNames: string[],
  opts?: { suspense?: boolean },
) =>
  useSittingUploadedResults(assessmentName, studentNames, {
    select: data =>
      data.uploadedResults.reduce((acc, result) => {
        const studentId = result.studentName.split('/')[1];
        acc.set(studentId, (acc.get(studentId) || []).concat([result]));
        return acc;
      }, new Map<string, UploadedAssessmentResult[]>()),
    ...opts,
  });
