// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/bookmark/reporting/v1/reporting.proto" (package "sparx.reading.bookmark.reporting.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { UserBook } from '../../v1/appserver';
import { Book } from '../../../books/v1/book';
import { Timestamp } from '../../../../../../google/protobuf/timestamp';
import { BookLog } from '../../v1/appserver';
/**
 * @generated from protobuf message sparx.reading.bookmark.reporting.v1.SchoolSettings
 */
export interface SchoolSettings {
  /**
   * Type maps to a smmsg.StudentGroup type (but without the import)
   *
   * @generated from protobuf field: int32 student_group_type = 1;
   */
  studentGroupType: number;
  /**
   * @generated from protobuf field: map<string, string> annotations = 2;
   */
  annotations: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf message sparx.reading.bookmark.reporting.v1.UserBookLog
 */
export interface UserBookLog {
  /**
   * @generated from protobuf field: string user_book_log_id = 1;
   */
  userBookLogId: string;
  /**
   * @generated from protobuf field: string user_id = 2;
   */
  userId: string;
  /**
   * @generated from protobuf field: string book_name = 3;
   */
  bookName: string;
  /**
   * @generated from protobuf field: int32 read_index = 4;
   */
  readIndex: number;
  /**
   * @generated from protobuf field: double progress = 5;
   */
  progress: number;
  /**
   * @generated from protobuf field: sparx.reading.bookmark.v1.BookLog state = 6;
   */
  state?: BookLog;
  /**
   * @generated from protobuf field: string package_name = 7;
   */
  packageName: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 8;
   */
  timestamp?: Timestamp;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.reporting.v1.UserBookmark
 */
export interface UserBookmark {
  /**
   * @generated from protobuf field: string subject = 1;
   */
  subject: string;
  /**
   * @generated from protobuf field: string user_id = 2;
   */
  userId: string;
  /**
   * @generated from protobuf field: bytes value = 3;
   */
  value: Uint8Array;
  /**
   * @generated from protobuf field: bytes image = 4;
   */
  image: Uint8Array;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.reporting.v1.GetSchoolSettingsRequest
 */
export interface GetSchoolSettingsRequest {
  /**
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.reporting.v1.GetSchoolSettingsResponse
 */
export interface GetSchoolSettingsResponse {
  /**
   * @generated from protobuf field: sparx.reading.bookmark.reporting.v1.SchoolSettings settings = 1;
   */
  settings?: SchoolSettings;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.reporting.v1.ListUserBookLogsRequest
 */
export interface ListUserBookLogsRequest {
  /**
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.reporting.v1.ListUserBookLogsResponse
 */
export interface ListUserBookLogsResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.bookmark.reporting.v1.UserBookLog user_book_logs = 1;
   */
  userBookLogs: UserBookLog[];
}
/**
 * @generated from protobuf message sparx.reading.bookmark.reporting.v1.ListUserBooksRequest
 */
export interface ListUserBooksRequest {
  /**
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.reporting.v1.ListUserBooksResponse
 */
export interface ListUserBooksResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.bookmark.reporting.v1.ListUserBooksResponse.UserBook user_books = 1;
   */
  userBooks: ListUserBooksResponse_UserBook[];
  /**
   * @deprecated
   * @generated from protobuf field: repeated sparx.reading.books.v1.Book books = 2 [deprecated = true];
   */
  books: Book[];
}
/**
 * @generated from protobuf message sparx.reading.bookmark.reporting.v1.ListUserBooksResponse.UserBook
 */
export interface ListUserBooksResponse_UserBook {
  /**
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
  /**
   * @generated from protobuf field: sparx.reading.bookmark.v1.UserBook user_book = 2;
   */
  userBook?: UserBook;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.reporting.v1.QueryBooksRequest
 */
export interface QueryBooksRequest {
  /**
   * @generated from protobuf field: repeated string names = 1;
   */
  names: string[];
}
/**
 * @generated from protobuf message sparx.reading.bookmark.reporting.v1.QueryBooksResponse
 */
export interface QueryBooksResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.books.v1.Book books = 1;
   */
  books: Book[];
}
/**
 * @generated from protobuf message sparx.reading.bookmark.reporting.v1.GetBookmarksForUsersRequest
 */
export interface GetBookmarksForUsersRequest {
  /**
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * @generated from protobuf field: repeated string subjects = 2;
   */
  subjects: string[];
}
/**
 * @generated from protobuf message sparx.reading.bookmark.reporting.v1.GetBookmarksForUsersResponse
 */
export interface GetBookmarksForUsersResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.bookmark.reporting.v1.UserBookmark user_bookmarks = 1;
   */
  userBookmarks: UserBookmark[];
}
// @generated message type with reflection information, may provide speed optimized methods
class SchoolSettings$Type extends MessageType<SchoolSettings> {
  constructor() {
    super('sparx.reading.bookmark.reporting.v1.SchoolSettings', [
      {
        no: 1,
        name: 'student_group_type',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 2,
        name: 'annotations',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.reporting.v1.SchoolSettings
 */
export const SchoolSettings = new SchoolSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserBookLog$Type extends MessageType<UserBookLog> {
  constructor() {
    super('sparx.reading.bookmark.reporting.v1.UserBookLog', [
      {
        no: 1,
        name: 'user_book_log_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'book_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'read_index', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 5, name: 'progress', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 6, name: 'state', kind: 'message', T: () => BookLog },
      {
        no: 7,
        name: 'package_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 8, name: 'timestamp', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.reporting.v1.UserBookLog
 */
export const UserBookLog = new UserBookLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserBookmark$Type extends MessageType<UserBookmark> {
  constructor() {
    super('sparx.reading.bookmark.reporting.v1.UserBookmark', [
      { no: 1, name: 'subject', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'value', kind: 'scalar', T: 12 /*ScalarType.BYTES*/ },
      { no: 4, name: 'image', kind: 'scalar', T: 12 /*ScalarType.BYTES*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.reporting.v1.UserBookmark
 */
export const UserBookmark = new UserBookmark$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolSettingsRequest$Type extends MessageType<GetSchoolSettingsRequest> {
  constructor() {
    super('sparx.reading.bookmark.reporting.v1.GetSchoolSettingsRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.reporting.v1.GetSchoolSettingsRequest
 */
export const GetSchoolSettingsRequest = new GetSchoolSettingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolSettingsResponse$Type extends MessageType<GetSchoolSettingsResponse> {
  constructor() {
    super('sparx.reading.bookmark.reporting.v1.GetSchoolSettingsResponse', [
      { no: 1, name: 'settings', kind: 'message', T: () => SchoolSettings },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.reporting.v1.GetSchoolSettingsResponse
 */
export const GetSchoolSettingsResponse = new GetSchoolSettingsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserBookLogsRequest$Type extends MessageType<ListUserBookLogsRequest> {
  constructor() {
    super('sparx.reading.bookmark.reporting.v1.ListUserBookLogsRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.reporting.v1.ListUserBookLogsRequest
 */
export const ListUserBookLogsRequest = new ListUserBookLogsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserBookLogsResponse$Type extends MessageType<ListUserBookLogsResponse> {
  constructor() {
    super('sparx.reading.bookmark.reporting.v1.ListUserBookLogsResponse', [
      {
        no: 1,
        name: 'user_book_logs',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => UserBookLog,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.reporting.v1.ListUserBookLogsResponse
 */
export const ListUserBookLogsResponse = new ListUserBookLogsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserBooksRequest$Type extends MessageType<ListUserBooksRequest> {
  constructor() {
    super('sparx.reading.bookmark.reporting.v1.ListUserBooksRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.reporting.v1.ListUserBooksRequest
 */
export const ListUserBooksRequest = new ListUserBooksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserBooksResponse$Type extends MessageType<ListUserBooksResponse> {
  constructor() {
    super('sparx.reading.bookmark.reporting.v1.ListUserBooksResponse', [
      {
        no: 1,
        name: 'user_books',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ListUserBooksResponse_UserBook,
      },
      {
        no: 2,
        name: 'books',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Book,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.reporting.v1.ListUserBooksResponse
 */
export const ListUserBooksResponse = new ListUserBooksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserBooksResponse_UserBook$Type extends MessageType<ListUserBooksResponse_UserBook> {
  constructor() {
    super(
      'sparx.reading.bookmark.reporting.v1.ListUserBooksResponse.UserBook',
      [
        { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        { no: 2, name: 'user_book', kind: 'message', T: () => UserBook },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.reporting.v1.ListUserBooksResponse.UserBook
 */
export const ListUserBooksResponse_UserBook =
  new ListUserBooksResponse_UserBook$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QueryBooksRequest$Type extends MessageType<QueryBooksRequest> {
  constructor() {
    super('sparx.reading.bookmark.reporting.v1.QueryBooksRequest', [
      {
        no: 1,
        name: 'names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.reporting.v1.QueryBooksRequest
 */
export const QueryBooksRequest = new QueryBooksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QueryBooksResponse$Type extends MessageType<QueryBooksResponse> {
  constructor() {
    super('sparx.reading.bookmark.reporting.v1.QueryBooksResponse', [
      {
        no: 1,
        name: 'books',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Book,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.reporting.v1.QueryBooksResponse
 */
export const QueryBooksResponse = new QueryBooksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBookmarksForUsersRequest$Type extends MessageType<GetBookmarksForUsersRequest> {
  constructor() {
    super('sparx.reading.bookmark.reporting.v1.GetBookmarksForUsersRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'subjects',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.reporting.v1.GetBookmarksForUsersRequest
 */
export const GetBookmarksForUsersRequest =
  new GetBookmarksForUsersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBookmarksForUsersResponse$Type extends MessageType<GetBookmarksForUsersResponse> {
  constructor() {
    super('sparx.reading.bookmark.reporting.v1.GetBookmarksForUsersResponse', [
      {
        no: 1,
        name: 'user_bookmarks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => UserBookmark,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.reporting.v1.GetBookmarksForUsersResponse
 */
export const GetBookmarksForUsersResponse =
  new GetBookmarksForUsersResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.reading.bookmark.reporting.v1.Reporting
 */
export const Reporting = new ServiceType(
  'sparx.reading.bookmark.reporting.v1.Reporting',
  [
    {
      name: 'GetSchoolSettings',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:school',
          domain: '{school_name}',
        },
      },
      I: GetSchoolSettingsRequest,
      O: GetSchoolSettingsResponse,
    },
    {
      name: 'ListUserBookLogs',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:student',
          domain: '{school_name}',
        },
      },
      I: ListUserBookLogsRequest,
      O: ListUserBookLogsResponse,
    },
    {
      name: 'ListUserBooks',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:student',
          domain: '{school_name}',
        },
      },
      I: ListUserBooksRequest,
      O: ListUserBooksResponse,
    },
    {
      name: 'QueryBooks',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'contentsummaries:books',
          domain: 'content',
        },
      },
      I: QueryBooksRequest,
      O: QueryBooksResponse,
    },
    {
      name: 'GetBookmarksForUsers',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:student',
          domain: '{school_name}',
        },
      },
      I: GetBookmarksForUsersRequest,
      O: GetBookmarksForUsersResponse,
    },
  ],
);
