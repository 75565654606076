import {
  ListUserBooksResponse_UserBook,
  UserBookLog,
} from '@sparx/api/apis/sparx/reading/bookmark/reporting/v1/reporting';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { Timestamp as pbTimestamp } from '@sparx/api/google/protobuf/timestamp';
import { format, isBefore, isToday } from 'date-fns';
import { useMemo } from 'react';
import { studentsInGroup } from 'utils/students';
import { DayMilestone, getMilestonesAchieved } from 'views/certificateview/milestones';

interface UserBookLogWithTimestamp {
  log: UserBookLog;
  timestamp: Date;
}

export const useLogsWithTimestamp = (logs: UserBookLog[] | undefined) =>
  useMemo(
    () =>
      (logs || [])
        .filter(log => log.timestamp)
        .map(log => ({
          log,
          timestamp: pbTimestamp.toDate(log.timestamp!),
        })),
    [logs],
  );

export const useLogsBetweenReportingPeriod = (
  logs: UserBookLog[] | undefined,
  start: Date,
  end: Date,
): UserBookLogWithTimestamp[] =>
  useMemo(
    () =>
      (logs || [])
        ?.map(log => {
          if (!log.timestamp) return null;

          // Only include logs between dates
          const timestamp = pbTimestamp.toDate(log.timestamp);
          if (timestamp < start || timestamp >= end) {
            return null;
          }

          return { log, timestamp };
        })
        .filter(v => !!v),
    [logs, start, end],
  );

export const useBookLogStats = (logs: UserBookLogWithTimestamp[]) =>
  useMemo(() => {
    const studentReadToday: Record<string, boolean> = {};
    const studentDaysRead: Record<string, Set<string>> = {};
    const studentLogs: Record<string, UserBookLog[]> = {};

    for (const { log, timestamp } of logs) {
      if (isToday(timestamp)) {
        studentReadToday[log.userId] = true;
      }
      const dayString = format(timestamp, 'yyyy-MM-dd');

      if (!studentDaysRead[log.userId]) studentDaysRead[log.userId] = new Set<string>();
      studentDaysRead[log.userId].add(dayString);

      studentLogs[log.userId] = [...(studentLogs[log.userId] || []), log];
    }

    return { studentReadToday, studentDaysRead };
  }, [logs]);

export interface StudentPages {
  totalPages: number;
  pagesPerBook: Record<string, number>;
  totalWords: number;
  wordsPerBook: Record<string, number>;
  numberLogs: number;

  wordsReadIncomplete: boolean;
  pagesReadSus: boolean;
}

export const useBooklogPageStats = (
  logs?: UserBookLogWithTimestamp[],
  userBooks?: ListUserBooksResponse_UserBook[],
) => {
  const userBookLookup = useMemo(() => {
    const lookup: Record<string, ListUserBooksResponse_UserBook> = {};
    for (const userBook of userBooks || []) {
      lookup[userBook.userId + '/' + userBook.userBook?.bookName] = userBook;
    }
    return lookup;
  }, [userBooks]);

  return useMemo(() => {
    const studentPagesRead: Record<string, StudentPages> = {};
    for (const { log } of logs || []) {
      const book = userBookLookup[log.userId + '/' + log.bookName];
      const bookConfig = book.userBook?.state?.config;

      if (book?.userBook) {
        const startPage = Math.max(log.state?.startPage || 0, 0);
        const maxPage = bookConfig?.hasConfig ? bookConfig.endPage : 1000;
        const endPage = Math.min(log.state?.endPage || 0, maxPage);
        const pages = Math.max(0, endPage - startPage);

        const wordsPerPage = book.userBook?.state?.config?.silverReaderConfig?.wordsPerPage || 0;
        const words = pages * wordsPerPage;

        if (!studentPagesRead[log.userId])
          studentPagesRead[log.userId] = {
            totalPages: 0,
            pagesPerBook: {},
            totalWords: 0,
            wordsPerBook: {},
            numberLogs: 0,
            wordsReadIncomplete: false,
            pagesReadSus: false,
          };

        studentPagesRead[log.userId].numberLogs++;
        studentPagesRead[log.userId].totalPages += pages;
        studentPagesRead[log.userId].pagesPerBook[log.bookName] =
          (studentPagesRead[log.userId].pagesPerBook[log.bookName] || 0) + pages;

        studentPagesRead[log.userId].totalWords += words;
        studentPagesRead[log.userId].wordsPerBook[log.bookName] =
          (studentPagesRead[log.userId].wordsPerBook[log.bookName] || 0) + words;
        if (wordsPerPage === 0) {
          studentPagesRead[log.userId].wordsReadIncomplete = true;
        }

        if (pages >= 100) {
          studentPagesRead[log.userId].pagesReadSus = true;
        }
      }
    }
    return studentPagesRead;
  }, [logs, userBookLookup]);
};

export interface GroupStat {
  group: Group;
  readToday: number;
  readTodayPercent: number;
  total: number;
  daysRead: { bad: number; okay: number; good: number };
}

export const useGroupStats = (
  groups?: Group[],
  students?: Student[],
  studentReadToday?: Record<string, boolean>,
  studentDaysRead?: Record<string, Set<string>>,
): GroupStat[] =>
  useMemo(() => {
    return (
      groups?.map(group => {
        const groupStudents = studentsInGroup(students || [], group.name);
        const readToday = groupStudents.filter(s => studentReadToday?.[s.studentId]).length;

        const daysRead = { bad: 0, okay: 0, good: 0 };
        for (const student of groupStudents) {
          const days = studentDaysRead?.[student.studentId]?.size || 0;

          if (days >= 3) daysRead.good++;
          else if (days > 0) daysRead.okay++;
          else daysRead.bad++;
        }

        const readTodayPercent = (readToday / groupStudents.length) * 100;

        return {
          group,
          readToday,
          readTodayPercent,
          total: groupStudents.length,
          daysRead,
        };
      }) || []
    );
  }, [groups, students, studentReadToday, studentDaysRead]);

const fakeMultiplier = 1;

export const useStudentMilestonesToDate = (logs: UserBookLogWithTimestamp[], date: Date) =>
  useMemo(() => {
    const dayTracker: Record<string, Set<string>> = {};
    const milestonesAchieved: Record<string, Map<DayMilestone, Date>> = {};

    // Ensure the logs are sorted by asc timestamp
    logs.sort((a, b) => (isBefore(a.timestamp, b.timestamp) ? -1 : 1));

    for (const { log, timestamp } of logs) {
      if (!isBefore(timestamp, date)) {
        continue; // ignore this log
      }
      const daysReadBefore = (dayTracker[log.userId]?.size || 0) * fakeMultiplier;

      const dayString = format(timestamp, 'yyyy-MM-dd');
      dayTracker[log.userId] = dayTracker[log.userId] || new Set<string>();
      dayTracker[log.userId].add(dayString);

      // Check if any milestones have been achieved
      const daysReadAfter = dayTracker[log.userId].size * fakeMultiplier;
      if (daysReadBefore != daysReadAfter) {
        const milestones = getMilestonesAchieved(daysReadBefore, daysReadAfter);
        for (const milestone of milestones) {
          milestonesAchieved[log.userId] =
            milestonesAchieved[log.userId] || new Map<DayMilestone, Date>();
          if (!milestonesAchieved[log.userId].get(milestone)) {
            milestonesAchieved[log.userId].set(milestone, timestamp);
          }
        }
      }
    }

    const days = new Map<string, number>();
    for (const [studentId, daysRead] of Object.entries(dayTracker)) {
      days.set(studentId, daysRead.size * fakeMultiplier);
    }

    return { milestones: milestonesAchieved, days };
  }, [logs, date]);
