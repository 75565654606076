import { ThemeProvider } from '@sparx/design/context';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ensureTokenFetcherStarted } from 'api/auth';
import { QueryProvider } from 'app/QueryProvider';
import { SuspenseRoute } from 'app/SuspenseRoute';
import { useSession } from 'queries/sessions';
import { RouterProvider } from 'react-router-dom';

import { router } from './router';
import sparxDesignStyles from './SparxDesign.module.css';

ensureTokenFetcherStarted();

export const App = () => {
  return (
    <ThemeProvider themeStyles={sparxDesignStyles}>
      <SuspenseRoute>
        <QueryProvider>
          <AppRouter />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryProvider>
      </SuspenseRoute>
    </ThemeProvider>
  );
};

const AppRouter = () => {
  useSession({ suspense: true });
  return <RouterProvider router={router} />;
};
