import {
  getMonth,
  isSaturday,
  nextSaturday,
  previousSaturday,
  setHours,
  startOfDay,
} from 'date-fns';
import { useMemo } from 'react';

const reportingHour = 0;

const getThisWeekReportingPeriod = () => {
  const now = new Date();
  let start;
  if (isSaturday(now)) {
    start = startOfDay(now);
  } else {
    start = previousSaturday(startOfDay(now));
  }
  start = setHours(start, reportingHour);
  const end = setHours(nextSaturday(start), reportingHour);
  return { start, end };
};

const getLastWeekReportingPeriod = () => {
  const now = new Date();
  let start;
  if (isSaturday(now)) {
    start = previousSaturday(startOfDay(now));
  } else {
    start = previousSaturday(previousSaturday(startOfDay(now)));
  }
  start = setHours(start, reportingHour);
  const end = setHours(nextSaturday(start), reportingHour);
  return { start, end };
};

export const getStartOfAcademicYear = (date: Date) => {
  const startYear = getMonth(date) > 8 ? date.getFullYear() : date.getFullYear() - 1;
  return new Date(startYear, 8, 1);
};

const getThisAcademicYearReportingPeriod = () => {
  const now = new Date();
  const startYear = getMonth(now) > 8 ? now.getFullYear() : now.getFullYear() - 1;
  return {
    start: new Date(startYear, 8, 1),
    end: new Date(startYear + 1, 8, 1),
  };
};

export type ReportingPeriod = 'thisweek' | 'lastweek' | 'thisyear';

export const useReportingPeriod = (period: ReportingPeriod) =>
  useMemo(() => {
    switch (period) {
      case 'thisweek':
        return getThisWeekReportingPeriod();
      case 'lastweek':
        return getLastWeekReportingPeriod();
      case 'thisyear':
        return getThisAcademicYearReportingPeriod();
      default:
        throw new Error('Invalid reporting period');
    }
  }, [period]);
