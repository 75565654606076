import { RpcError } from '@protobuf-ts/runtime-rpc';
import {
  ListStudentsRequest,
  ListStudentsResponse,
  Student,
} from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getSchoolIdFromSession, studentClient } from 'api/auth';

export const useStudents = (opts?: UseQueryOptions<ListStudentsResponse, RpcError, Student[]>) =>
  useQuery({
    queryKey: ['students'],
    queryFn: async () =>
      studentClient.listStudents(
        ListStudentsRequest.create({
          schoolId: await getSchoolIdFromSession(),
        }),
      ).response,
    select: data => data.students,
    staleTime: 10 * 60 * 1000, // 10 minutes
    ...opts,
  });
