// Import PointerEvents polyfill for safari
import 'pepjs';
import './index.css';
import '@sparx/react-utils/nodeRemoveFix';

import { App } from 'app/App';
import React from 'react';
import ReactDOM from 'react-dom/client';
// ResizeObserver (for old Safari)
import ResizeObserverPolyfill from 'resize-observer-polyfill';
window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;

declare global {
  interface Window {
    settings?: {
      envName?: 'dev' | 'test2' | 'test1' | 'live';
      authUrl?: string;
      apiUrl?: string;
      selectSchoolUrl?: string;
      interactionGatewayUrl?: string;
      assetsUrl?: string;
      readerApiUrl?: string;
    };
  }
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.Fragment>
    <App />
  </React.Fragment>,
);
