import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import React, { ComponentProps, forwardRef, PropsWithChildren } from 'react';

import styles from './Menu.module.css';

export const MenuButton = forwardRef(
  ({ children, ...rest }: ComponentProps<'button'>, ref: React.ForwardedRef<HTMLButtonElement>) => (
    <button className={styles.MenuButton} {...rest} ref={ref}>
      <span className={styles.MenuText}>{children}</span>
      <FontAwesomeIcon icon={faCaretDown} />
    </button>
  ),
);

const DropdownContent = DropdownMenu.Content;

export const DropdownMenuContent = ({
  children,
  rounded,
  className,
  ...rest
}: PropsWithChildren<{
  rounded?: 'bottom-left' | 'bottom' | 'from-top-left' | 'all';
}> &
  ComponentProps<typeof DropdownContent>) => (
  <DropdownMenu.Portal>
    <DropdownMenu.Content
      {...rest}
      className={classNames(
        styles.DropdownMenuContent,
        {
          [styles.DropdownMenuContentRoundedBottom]: rounded === 'bottom',
          [styles.DropdownMenuContentRoundedAll]: rounded === 'all',
          [styles.DropdownMenuContentFromTopLeft]: rounded === 'from-top-left',
        },
        className,
      )}
    >
      {children}
    </DropdownMenu.Content>
  </DropdownMenu.Portal>
);

export const DropdownMenuLabel = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => (
  <DropdownMenu.Label className={classNames(styles.DropdownMenuLabel, className)}>
    {children}
  </DropdownMenu.Label>
);

export const DropdownMenuItem = ({
  children,
  icon,
  className,
  ...rest
}: ComponentProps<typeof DropdownMenu.Item> & {
  icon?: IconDefinition;
}) => (
  <DropdownMenu.Item className={classNames(styles.DropdownMenuItem, className)} {...rest}>
    {icon && <DropdownMenuIcon icon={icon} />}
    {children}
  </DropdownMenu.Item>
);

export const DropdownMenuIcon = ({ icon }: { icon: IconDefinition }) => (
  <span className={styles.DropdownMenuItemIcon}>
    <FontAwesomeIcon icon={icon} fixedWidth={true} />
  </span>
);

export const DropdownSeparator = () => (
  <DropdownMenu.Separator className={styles.DropdownMenuSeparator} />
);

export const DropdownMenuContainer = ({
  children,
  className,
}: PropsWithChildren<{
  className?: string;
}>) => <div className={classNames(styles.DropdownMenuItem, className)}>{children}</div>;
