// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/landing/v1/landing.proto" (package "sparx.landing.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { UserProductDataService } from './landing';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { UserProductData } from './landing';
import type { GetUserProductDataRequest } from './landing';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.landing.v1.UserProductDataService
 */
export interface IUserProductDataServiceClient {
  /**
   * Fetches the product data for the currently authenticated user. The user can only request data
   * for the school they are authenticated against.
   *
   * @generated from protobuf rpc: GetUserProductData(sparx.landing.v1.GetUserProductDataRequest) returns (sparx.landing.v1.UserProductData);
   */
  getUserProductData(
    input: GetUserProductDataRequest,
    options?: RpcOptions,
  ): UnaryCall<GetUserProductDataRequest, UserProductData>;
}
/**
 * @generated from protobuf service sparx.landing.v1.UserProductDataService
 */
export class UserProductDataServiceClient
  implements IUserProductDataServiceClient, ServiceInfo
{
  typeName = UserProductDataService.typeName;
  methods = UserProductDataService.methods;
  options = UserProductDataService.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * Fetches the product data for the currently authenticated user. The user can only request data
   * for the school they are authenticated against.
   *
   * @generated from protobuf rpc: GetUserProductData(sparx.landing.v1.GetUserProductDataRequest) returns (sparx.landing.v1.UserProductData);
   */
  getUserProductData(
    input: GetUserProductDataRequest,
    options?: RpcOptions,
  ): UnaryCall<GetUserProductDataRequest, UserProductData> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetUserProductDataRequest, UserProductData>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
}
