import { faArrowRight, faCertificate, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { Button } from '@sparx/design/components';
import { Stack } from '@sparx/sparx-design/components';
import { Alert } from '@sparx/sparx-design/components/alert/Alert';
import { FormControl } from '@sparx/sparx-design/components/form/FormControl';
import { FormLabel } from '@sparx/sparx-design/components/form/FormLabel';
import { Input } from '@sparx/sparx-design/components/input/Input';
import { useClassSelectionStudents } from 'components/header/NavigationControllerProvider';
import { PageHeader, PageHeaderSubpage } from 'components/pageheader/PageHeader';
import { PageContainer } from 'components/pages/PageContainer';
import { format } from 'date-fns';
import { useUserBookLogs } from 'queries/reporting';
import { useSchool } from 'queries/school';
import { useMemo, useState } from 'react';
import { PDFViewer } from 'react-pdf-4';
import { getStartOfAcademicYear } from 'utils/period';
import { useLogsWithTimestamp, useStudentMilestonesToDate } from 'utils/stats';
import { CertificateGroup, CertificateOptions } from 'views/certificateview/Certificate';
import {
  DayMilestone,
  dayMilestoneColours,
  daysAchievementOpts,
} from 'views/certificateview/milestones';

import styles from './CertificateGenerationView.module.css';

interface MilestoneWithDate {
  milestone: DayMilestone;
  date: Date;
}

export const CertificateGenerationView = () => {
  const { data: logs } = useUserBookLogs({ suspense: true });
  const logsWithTs = useLogsWithTimestamp(logs?.userBookLogs);

  const [startDate, setStartDate] = useState(getStartOfAcademicYear(new Date()));
  const [endDate, setEndDate] = useState(new Date());
  const [teachers, setTeachers] = useState<string[]>(['']);
  const addTeacher = () => setTeachers([...teachers, '']);
  const removeTeacher = (index: number) => {
    const newTeachers = [...teachers];
    newTeachers.splice(index, 1);
    setTeachers(newTeachers);
  };
  const updateTeacher = (index: number, value: string) => {
    const newTeachers = [...teachers];
    newTeachers[index] = value;
    setTeachers(newTeachers);
  };

  const { milestones: before, days: daysBefore } = useStudentMilestonesToDate(
    logsWithTs,
    startDate,
  );
  const { milestones: after, days: daysAfter } = useStudentMilestonesToDate(logsWithTs, endDate);

  const { students, selectedGroup } = useClassSelectionStudents();

  const studentMilestones = useNewStudentMilestones(students, before, after);

  const studentsWithMilestones = useMemo(
    () =>
      students
        .map(student => ({
          student,
          milestones: studentMilestones.get(student.studentId) || [],
        }))
        .sort(
          (a, b) =>
            (b.milestones.length > 0 ? 1 : 0) - (a.milestones.length > 0 ? 1 : 0) ||
            a.student.familyName.localeCompare(b.student.familyName) ||
            a.student.givenName.localeCompare(b.student.givenName),
        ),
    [students, studentMilestones],
  );

  const numberCertificatesToPrint = studentsWithMilestones.reduce(
    (acc, { milestones }) => acc + milestones.length,
    0,
  );
  const generateEnabled = teachers.every(t => t) && numberCertificatesToPrint > 0;

  const { data: school } = useSchool({ suspense: true });
  const [certificates, setCertificates] = useState<CertificateOptions[] | undefined>();
  const generateCertificates = () => {
    const opts: CertificateOptions[] = [];
    for (const student of studentsWithMilestones) {
      for (const milestone of student.milestones) {
        opts.push({
          name: `${student.student.givenName} ${student.student.familyName}`,
          schoolName: school?.displayName || '',
          date: milestone.date,
          ...daysAchievementOpts(milestone.milestone),
          teachers: teachers.filter(t => t),
        });
      }
    }
    setCertificates(opts);
  };

  if (certificates != undefined) {
    return (
      <CertificateView certificates={certificates} onClose={() => setCertificates(undefined)} />
    );
  }

  if (selectedGroup?.type !== 'group') {
    return <></>;
  }

  return (
    <PageContainer>
      <PageHeader
        right={
          <Button
            rightIcon={<FontAwesomeIcon icon={faArrowRight} />}
            onClick={generateCertificates}
            isDisabled={!generateEnabled}
          >
            Generate certificates
          </Button>
        }
      >
        Certificate Generation
        <PageHeaderSubpage>{selectedGroup.group.displayName}</PageHeaderSubpage>
      </PageHeader>

      <div className={styles.SplitContainer}>
        <Stack direction="column" spacing={3} className={styles.FormContainer}>
          <FormControl>
            <FormLabel>Start date</FormLabel>
            <Input
              type="datetime-local"
              value={startDate.toISOString().slice(0, 16)}
              onChange={e => setStartDate(new Date(e.target.value))}
            />
          </FormControl>
          <FormControl>
            <FormLabel>End date</FormLabel>
            <Input
              type="datetime-local"
              value={endDate.toISOString().slice(0, 16)}
              onChange={e => setEndDate(new Date(e.target.value))}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Teachers</FormLabel>
            <Stack direction="column" spacing={2}>
              {teachers.map((teacher, i) => (
                <div key={i} className={styles.TeacherInput}>
                  <Input
                    value={teacher}
                    onChange={e => updateTeacher(i, e.target.value)}
                    placeholder="Teacher name"
                    isInvalid={!teacher}
                  />
                  <Button
                    size="sm"
                    variant="secondary"
                    type="button"
                    onClick={() => removeTeacher(i)}
                  >
                    Remove
                  </Button>
                </div>
              ))}
              <Button
                leftIcon={<FontAwesomeIcon icon={faPlus} />}
                type="button"
                onClick={addTeacher}
                isDisabled={teachers.length >= 3}
              >
                Add teacher
              </Button>
            </Stack>
          </FormControl>
        </Stack>
        <div className={styles.MilestoneContainer}>
          {numberCertificatesToPrint > 0 && (
            <Alert>
              <Alert.Icon />
              <Alert.Description>
                {numberCertificatesToPrint} new certificate{numberCertificatesToPrint === 1 || 's'}{' '}
                to print
              </Alert.Description>
            </Alert>
          )}
          <PreviewMilestones
            studentsWithMilestones={studentsWithMilestones}
            daysBefore={daysBefore}
            daysAfter={daysAfter}
          />
        </div>
      </div>
    </PageContainer>
  );
};

const useNewStudentMilestones = (
  students: Student[],
  beforeMs: Record<string, Map<DayMilestone, Date>>,
  afterMs: Record<string, Map<DayMilestone, Date>>,
) =>
  useMemo(() => {
    const studentMilestoneLookup = new Map<string, MilestoneWithDate[]>();
    for (const student of students) {
      const milestonesBefore = beforeMs[student.studentId];

      const newMilestones = new Map(afterMs[student.studentId]);
      // Remove all previously achieved milestones
      for (const key of milestonesBefore?.keys() || []) {
        newMilestones.delete(key);
      }

      const milestones: MilestoneWithDate[] = [];
      for (const [milestone, date] of newMilestones) {
        milestones.push({ milestone, date });
      }
      milestones.sort((a, b) => a.milestone - b.milestone);
      studentMilestoneLookup.set(student.studentId, milestones);
    }
    return studentMilestoneLookup;
  }, [students, beforeMs, afterMs]);

const PreviewMilestones = ({
  studentsWithMilestones,
  daysBefore,
  daysAfter,
}: {
  studentsWithMilestones: { student: Student; milestones: MilestoneWithDate[] }[];
  daysBefore: Map<string, number>;
  daysAfter: Map<string, number>;
}) => (
  <>
    {studentsWithMilestones.map(({ student, milestones }) => (
      <div key={student.studentId} className={styles.Student}>
        <div className={styles.StudentHeader}>
          <h4>
            {student.givenName} {student.familyName}
          </h4>
          <span className={styles.StudentDifference}>
            {daysBefore.get(student.studentId) || 0}
            <FontAwesomeIcon icon={faArrowRight} />
            {daysAfter.get(student.studentId) || 0}
          </span>
        </div>
        {milestones?.map(({ milestone, date }) => (
          <div className={styles.Milestone} key={milestone}>
            <span>
              <FontAwesomeIcon
                icon={faCertificate}
                style={{ color: dayMilestoneColours[milestone][1] }}
              />
              Achieved {milestone} days milestone
            </span>
            <span className={styles.MilestoneDate}>{format(date, 'dd/MM/yyyy')}</span>
          </div>
        ))}
        {!milestones?.length && <div className={styles.NoMilestone}>No milestones achieved</div>}
      </div>
    ))}
  </>
);

const CertificateView = ({
  certificates,
  onClose,
}: {
  certificates: CertificateOptions[];
  onClose: () => void;
}) => (
  <div className={styles.CertificateView}>
    <div className={styles.CertificateViewHeader}>
      <h3>Generated Certificates</h3>
      <Button
        onClick={onClose}
        size="sm"
        leftIcon={<FontAwesomeIcon icon={faTimes} />}
        colourScheme="neutral"
      >
        Close
      </Button>
    </div>
    <PDFViewer className={styles.PDFView} showToolbar={true}>
      <CertificateGroup certs={certificates} />
    </PDFViewer>
  </div>
);
