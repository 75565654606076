import styles from './ProgressBar.module.css';

interface Progress {
  value: number;
  color: string;
}

interface ProgressBarProps {
  total: number;
  bars: Progress[];
}

export const ProgressBar = ({ total, bars }: ProgressBarProps) => (
  <div className={styles.Container}>
    <div className={styles.Values}>
      {bars.map(
        (bar, index) =>
          bar.value > 0 && (
            <div
              key={index}
              className={styles.Value}
              style={{
                width: `${(bar.value / total) * 100}%`,
              }}
            >
              {bar.value}
            </div>
          ),
      )}
    </div>
    <div className={styles.Bar}>
      {bars.map(
        (bar, index) =>
          bar.value > 0 && (
            <div
              key={index}
              className={styles.Progress}
              style={{
                width: `${(bar.value / total) * 100}%`,
                backgroundColor: bar.color,
              }}
            />
          ),
      )}
    </div>
  </div>
);
