import { IdentifierType } from '@sparx/api/apis/sparx/reading/books/v1/book';
import { useQueries, useQuery } from '@tanstack/react-query';
import { create, windowScheduler } from '@yornaath/batshit';
import { reportingClient } from 'api/auth';

// TODO: this is copied from Reader and should be moved to a shared location

const bookBatchFetcher = create({
  fetcher: async (bookResourceNames: string[]) =>
    reportingClient.queryBooks({ names: bookResourceNames }).response.then(resp => resp.books),
  resolver: (items, query) =>
    items.find(
      item =>
        // Find by the root book name
        item.name === query ||
        // Find by alternate identifiers
        item.identifiers.find(
          id =>
            id.type === IdentifierType.SPARX_READER_UUID &&
            id.value === query.replace('books/', ''),
        ),
    ),
  scheduler: windowScheduler(100),
});

const bookMetadataFetcher = (bookId?: string) => ({
  queryKey: ['books', 'metadata', `books/${bookId}`],
  queryFn: async () => bookBatchFetcher.fetch('books/' + bookId),
  enabled: Boolean(bookId),
  staleTime: Infinity,
  cacheTime: Infinity,
  keepPreviousData: true,
});

/**
 * Fetch book from bookkeeper. Uses batshit to batch requests made within 100ms
 * of each other into a single QueryBooks call.
 */
export const useBookMetadata = (bookId?: string) => useQuery(bookMetadataFetcher(bookId));

export const useBookMetadataSuspense = (bookIdOrName?: string) =>
  useQuery({
    ...bookMetadataFetcher(bookIdOrName?.replace('books/', '')),
    suspense: true,
  });

export const useBatchBookMetadata = (bookIDs: string[]) =>
  useQueries({ queries: bookIDs.map(bookMetadataFetcher) });
