import { StudentGroupType } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { useGroups } from 'queries/groups';
import { useBookmarkSchool } from 'queries/school';

export const useBookmarkGroups = (opts?: { suspense?: boolean; enabled?: boolean }) => {
  const { data: school } = useBookmarkSchool(opts);
  const { data: groups } = useGroups(opts);

  const groupType = school?.settings?.studentGroupType || StudentGroupType.CLASS;
  return groups?.filter(g => g.type === groupType) || [];
};
