import { faCalendar, faCheck, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames';
import { useClassSelectionStudents } from 'components/header/NavigationControllerProvider';
import { PageHeader, PageHeaderSubpage } from 'components/pageheader/PageHeader';
import { PageContainer } from 'components/pages/PageContainer';
import { useReportingToggle } from 'components/reportingToggle';
import { DataTable } from 'components/table/DataTable';
import { PrettyTimestamp } from 'components/timestamp/PrettyTimestamp';
import { useUserBookLogs } from 'queries/reporting';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReportingPeriod } from 'utils/period';
import { useBookLogStats, useLogsBetweenReportingPeriod } from 'utils/stats';
import styles from 'views/trackingview/TrackingView.module.css';

interface Row {
  student: Student;
  displayName: string;
  readToday: boolean;
  daysRead: number;
}

export const TrackingView = () => {
  const { data: logs } = useUserBookLogs({ suspense: true });

  const { component, value: period } = useReportingToggle();
  const { start, end } = useReportingPeriod(period);
  const reportingLogs = useLogsBetweenReportingPeriod(logs?.userBookLogs, start, end);
  const { studentReadToday, studentDaysRead } = useBookLogStats(reportingLogs);

  const { students, selectedGroup } = useClassSelectionStudents();

  const studentCount = students.length;
  const studentsReadToday = useMemo(() => {
    return students.filter(student => studentReadToday[student.studentId]).length;
  }, [students, studentReadToday]);

  const navigate = useNavigate();

  const rows: Row[] = useMemo(
    () =>
      students.map(student => {
        return {
          student,
          displayName: `${student.givenName} ${student.familyName}`,
          readToday: studentReadToday[student.studentId] || false,
          daysRead: studentDaysRead[student.studentId]?.size || 0,
        };
      }),
    [students, studentReadToday, studentDaysRead],
  );

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<Row>();
    return [
      columnHelper.accessor(`displayName`, {
        header: 'Name',
        sortingFn: (a, b) =>
          // Sort by surname then first name
          a.original.student.familyName.localeCompare(b.original.student.familyName) ||
          a.original.student.givenName.localeCompare(b.original.student.givenName) ||
          a.original.student.studentId.localeCompare(b.original.student.studentId), // stability
        enableSorting: true,
        meta: {
          linkTo: (row: Row) => ({ to: `/teacher/student/${row.student.studentId}/logs` }),
        },
      }),
      columnHelper.accessor(`readToday`, {
        header: 'Read today',
        enableSorting: true,
        cell: ({ getValue }) => <ReadTodayCell readToday={getValue()} />,
      }),
      columnHelper.accessor(`daysRead`, {
        header: 'Days read',
        enableSorting: true,
        cell: ({ getValue }) => {
          const value = getValue();
          return (
            <div
              className={classNames(styles.DaysRead, {
                [styles.DaysReadOkay]: value > 0 && value < 3,
                [styles.DaysReadGood]: value >= 3,
              })}
            >
              {value} day{value === 1 ? '' : 's'}
            </div>
          );
        },
      }),
      columnHelper.display({
        id: 'view',
        meta: {
          width: 100,
        },
        cell: () => (
          <div data-row-hover-active="true" className={styles.ViewButton}>
            View
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        ),
      }),
    ];
  }, []);

  if (selectedGroup?.type !== 'group') {
    return <></>;
  }

  return (
    <PageContainer>
      <PageHeader title={`${selectedGroup.group.displayName} - Tracking`} right={component}>
        Tracking
        <PageHeaderSubpage>{selectedGroup.group.displayName}</PageHeaderSubpage>
      </PageHeader>

      <div className={styles.Stats}>
        <div className={styles.Stat}>
          <div className={styles.StatValue}>
            {studentsReadToday} / {studentCount}
          </div>
          <div className={styles.StatName}>Students read today</div>
        </div>
        <div className={classNames(styles.Stat, styles.StatReportingPeriod)}>
          <strong>
            <FontAwesomeIcon icon={faCalendar} />
            Reporting period
          </strong>
          <span>
            <strong className={styles.ReportingPeriodLabel}>Start:</strong>
            <PrettyTimestamp fmt={period === 'thisyear' ? 'EEEE do MMMM yyyy' : undefined}>
              {start}
            </PrettyTimestamp>
          </span>
          <span>
            <strong className={styles.ReportingPeriodLabel}>End:</strong>
            <PrettyTimestamp fmt={period === 'thisyear' ? 'EEEE do MMMM yyyy' : undefined}>
              {end}
            </PrettyTimestamp>
          </span>
        </div>
      </div>

      <DataTable
        data={rows}
        columns={columns}
        onRowClick={row => navigate(`/teacher/student/${row.student.studentId}/logs`)}
        defaultSort={[
          { id: 'readToday', desc: false },
          { id: 'daysRead', desc: false },
          { id: 'displayName', desc: false },
        ]}
      />
    </PageContainer>
  );
};

const ReadTodayCell = ({ readToday }: { readToday: boolean }) => {
  if (readToday) {
    return (
      <span className={styles.ReadToday}>
        <FontAwesomeIcon icon={faCheck} fixedWidth={true} />
        <span>Read today</span>
      </span>
    );
  } else {
    return (
      <span className={styles.NotReadToday}>
        <FontAwesomeIcon icon={faTimes} fixedWidth={true} />
        <span>Not read today</span>
      </span>
    );
  }
};
