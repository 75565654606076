import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { YearGroup } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { getSchool } from '@sparx/query/schools-service';
import { useLocalStorage } from '@sparx/react-utils';
import { useGroups } from 'queries/groups';
import { useStudents } from 'queries/students';
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { studentsInGroup } from 'utils/students';

interface NavigationControlContext {
  classSelectEnabled?: boolean;
  setClassSelectEnabled: (value: boolean) => void;
  selectedGroupName?: string;
  setSelectedGroupName: (name: string, replace?: boolean) => void;
  cachedGroupName?: string;
  setCachedGroupName: (name: string) => void;
}

const NavigationControlContext = React.createContext<NavigationControlContext>({
  setClassSelectEnabled: () => undefined,
  setSelectedGroupName: () => undefined,
  setCachedGroupName: () => undefined,
});

export const useNavigationControlContext = () => React.useContext(NavigationControlContext);

export const NavigationControlProvider = ({ children }: PropsWithChildren) => {
  const { data: school } = getSchool.useSuspenseQuery();
  const [classSelect, setClassSelect] = useState<boolean>();
  const [cachedGroupName, setCachedGroupName] = useLocalStorage(
    `primary/group/${school?.displayName}`,
  );

  const [urlGroupName, setUrlGroupName] = useSetGroupInUrl();
  const setGroup = (groupName: string, replace?: boolean) => {
    setCachedGroupName(() => groupName);
    if (urlGroupName !== groupName) {
      setUrlGroupName(groupName, { replace });
    }
  };

  return (
    <NavigationControlContext.Provider
      value={{
        classSelectEnabled: Boolean(classSelect),
        setClassSelectEnabled: val => setClassSelect(() => val),
        selectedGroupName: urlGroupName || undefined,
        setSelectedGroupName: setGroup,
        cachedGroupName: cachedGroupName || undefined,
        setCachedGroupName: setCachedGroupName,
      }}
    >
      {children}
    </NavigationControlContext.Provider>
  );
};

const useSetGroupInUrl = (): [
  string | undefined,
  (value: string, opts?: { replace?: boolean }) => void,
] => {
  const [params, setParams] = useSearchParams();
  const urlGroupName = params.get('group') || undefined;
  const set = (groupName: string, opts?: { replace?: boolean }) =>
    setParams(
      p => {
        p.set('group', groupName);
        return p;
      },
      { replace: opts?.replace },
    );
  return [urlGroupName, set];
};

export const useClassSelection = () => {
  const {
    setClassSelectEnabled,
    selectedGroupName,
    setSelectedGroupName,
    cachedGroupName,
    setCachedGroupName,
  } = React.useContext(NavigationControlContext);

  // Set the group in the URL when the selected group changes
  const [urlGroupName] = useSetGroupInUrl();
  useEffect(() => {
    if (!selectedGroupName && urlGroupName !== cachedGroupName && cachedGroupName) {
      setSelectedGroupName(cachedGroupName, true);
    } else if (urlGroupName !== cachedGroupName && urlGroupName) {
      setCachedGroupName(urlGroupName);
    }
  }, [selectedGroupName, urlGroupName, cachedGroupName, setCachedGroupName, setSelectedGroupName]);

  useEffect(() => {
    setClassSelectEnabled(true);
    return () => setClassSelectEnabled(false);
  }, [setClassSelectEnabled]);

  const { data: groups } = useGroups({ suspense: false });
  const selectedGroup = useSelectedGroup(selectedGroupName, groups);

  return { selectedGroupName, setSelectedGroupName, selectedGroup };
};

type SelectedGroup =
  | {
      type: 'group';
      group: Group;
    }
  | {
      type: 'yeargroup';
      yearGroup: YearGroup;
    }
  | undefined;

export const selectedGroupValue = (g: SelectedGroup) =>
  !g ? undefined : g.type === 'group' ? g.group : g.yearGroup;

export const useSelectedGroup = (
  selectedGroupName: string | undefined,
  groups: Group[] | undefined,
): SelectedGroup | undefined =>
  useMemo(() => {
    const group = groups?.find(g => g.name === selectedGroupName);
    if (group) {
      return { type: 'group', group };
    }
    return undefined;
  }, [groups, selectedGroupName]);

export const useClassSelectionStudents = () => {
  const selection = useClassSelection();
  const { data: groups = [] } = useGroups({ suspense: true });
  const { data: students = [] } = useStudents({ suspense: true });

  const filteredStudents = useMemo(() => {
    switch (selection.selectedGroup?.type) {
      case 'yeargroup': {
        const yearGroupId = selection.selectedGroup.yearGroup.yearGroupID;
        const ygGroups = groups
          .filter(g => g.yearGroupId === yearGroupId)
          .map(g => g.name.split('/')[3]);
        return students.filter(s => ygGroups.some(g => s.studentGroupIds.includes(g)));
      }
      case 'group': {
        return studentsInGroup(students, selection.selectedGroup.group.name);
      }
      default:
        return [];
    }
  }, [students, groups, selection.selectedGroup]);

  return { students: filteredStudents, groups, ...selection };
};
