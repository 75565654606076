// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/landing/v1/landing.proto" (package "sparx.landing.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Product } from '../../types/product';
/**
 * @generated from protobuf message sparx.landing.v1.UserProductData
 */
export interface UserProductData {
  /**
   * Display name for the user that requested the data.
   *
   * @generated from protobuf field: string display_name = 1;
   */
  displayName: string;
  /**
   * Type of the user that requested the data.
   *
   * @generated from protobuf field: sparx.landing.v1.UserType user_type = 2;
   */
  userType: UserType;
  /**
   * List of products the school has access to.
   *
   * @generated from protobuf field: repeated sparx.types.Product school_products = 3;
   */
  schoolProducts: Product[];
  /**
   * List of products the user has access to.
   *
   * @generated from protobuf field: repeated sparx.types.Product user_products = 4;
   */
  userProducts: Product[];
}
/**
 * @generated from protobuf message sparx.landing.v1.GetUserProductDataRequest
 */
export interface GetUserProductDataRequest {
  /**
   * School to fetch data for.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
}
/**
 * @generated from protobuf enum sparx.landing.v1.UserType
 */
export enum UserType {
  /**
   * @generated from protobuf enum value: USER_TYPE_UNSPECIFIED = 0;
   */
  USER_TYPE_UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: SPARXSTAFF = 1;
   */
  SPARXSTAFF = 1,
  /**
   * @generated from protobuf enum value: STAFF = 2;
   */
  STAFF = 2,
  /**
   * @generated from protobuf enum value: STUDENT = 3;
   */
  STUDENT = 3,
}
// @generated message type with reflection information, may provide speed optimized methods
class UserProductData$Type extends MessageType<UserProductData> {
  constructor() {
    super('sparx.landing.v1.UserProductData', [
      {
        no: 1,
        name: 'display_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'user_type',
        kind: 'enum',
        T: () => ['sparx.landing.v1.UserType', UserType],
      },
      {
        no: 3,
        name: 'school_products',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ['sparx.types.Product', Product],
      },
      {
        no: 4,
        name: 'user_products',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ['sparx.types.Product', Product],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.landing.v1.UserProductData
 */
export const UserProductData = new UserProductData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserProductDataRequest$Type extends MessageType<GetUserProductDataRequest> {
  constructor() {
    super('sparx.landing.v1.GetUserProductDataRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.landing.v1.GetUserProductDataRequest
 */
export const GetUserProductDataRequest = new GetUserProductDataRequest$Type();
/**
 * @generated ServiceType for protobuf service sparx.landing.v1.UserProductDataService
 */
export const UserProductDataService = new ServiceType(
  'sparx.landing.v1.UserProductDataService',
  [
    {
      name: 'GetUserProductData',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'user:productdata',
          domain: '{school_name}',
        },
      },
      I: GetUserProductDataRequest,
      O: UserProductData,
    },
  ],
);
