import { ListUserBooksResponse_UserBook } from '@sparx/api/apis/sparx/reading/bookmark/reporting/v1/reporting';
import { Book, IdentifierType } from '@sparx/api/apis/sparx/reading/books/v1/book';
import { useMemo } from 'react';

export const useBookLookup = (books: Book[]) =>
  useMemo(() => {
    const lookup: Record<string, Book> = {};
    for (const book of books) {
      lookup[book.name] = book;
      for (const id of book.identifiers) {
        if (id.type === IdentifierType.SPARX_READER_UUID) {
          const name = `books/${id.value}`;
          lookup[name] = book;
        }
      }
    }
    return lookup;
  }, [books]);

export const useUserUserBooks = (userBooks: ListUserBooksResponse_UserBook[], studentID?: string) =>
  useMemo(
    () =>
      userBooks
        .filter(ub => ub.userId === studentID)
        .sort(
          (a, b) =>
            (a.userBook?.lastReadTimestamp?.seconds || a.userBook?.startedTimestamp?.seconds || 0) -
            (b.userBook?.lastReadTimestamp?.seconds || a.userBook?.startedTimestamp?.seconds || 0),
        ),
    [userBooks, studentID],
  );
