import { format } from 'date-fns';
import {
  Defs,
  Document,
  Font,
  Image,
  LinearGradient,
  Page,
  Path,
  Rect,
  Stop,
  StyleSheet,
  Svg,
  Text,
  View,
} from 'react-pdf-4';

import Badge from './assets/icon_levelup.png';
import SparxLogo from './assets/sparx-learning.png';
import CaveatRegular from './fonts/Caveat-Regular.ttf';
import DMSansBold from './fonts/DMSans-Bold.ttf';
import DMSansRegular from './fonts/DMSans-Regular.ttf';

Font.register({
  src: DMSansRegular,
  family: 'DM Sans',
  fontWeight: 'normal',
});
Font.register({
  src: DMSansBold,
  family: 'DM Sans',
  fontWeight: 'bold',
});
Font.register({
  src: CaveatRegular,
  family: 'Caveat',
  fontWeight: 'normal',
});
Font.registerHyphenationCallback(word => [word]);

const signatureWidth = 170;

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'DM Sans',
  },
  borderContainer: {
    position: 'absolute',
    right: 20,
    bottom: 20,
    top: 20,
    left: 20,
    padding: 8,
  },
  container: {
    backgroundColor: 'white',
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flexGrow: 1,
    padding: 20,
    overflow: 'hidden',
  },
  svgBorderContainer: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    borderRadius: 26,
    border: '2px solid black',
  },
  main: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },

  logo: {
    width: '30%',
  },
  presentedTo: {
    fontSize: 18,
    color: '#473a6f',
  },
  name: {
    fontWeight: 'bold',
    fontSize: 60,
    letterSpacing: -2,
    color: '#19094c',
    marginBottom: 40,
    fontFamily: 'Caveat',
    lineHeight: 1,
  },

  signatures: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  signature: {
    display: 'flex',
    width: signatureWidth,
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: 5,
    marginRight: 5,
  },
  signatureLine: {
    height: 60,
    width: '100%',
    borderBottom: '2px solid #bab5c9',
  },
  signatureText: {
    color: '#473a6f',
    fontSize: 12,
    marginTop: 5,
  },

  schoolName: {
    color: '#473a6f',
    fontWeight: 'bold',
    fontSize: 25,
    lineHeight: 1.2,
    paddingTop: 20,
    width: '55%',
  },

  achievementDescription: {
    display: 'flex',
    flexDirection: 'row',
    width: '50%',
    color: '#473a6f',
    alignItems: 'center',
  },
  achievementDescriptionBadge: {
    width: 80,
    height: 80,
    marginRight: 20,
  },
  achievementDescriptionText: {
    textAlign: 'left',
  },

  cornerText: {
    position: 'absolute',
    color: 'white',
    bottom: 0,
    right: 10,
    width: 200,
    height: 70,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    fontSize: 50,
    fontWeight: 'bold',
    letterSpacing: -2,
  },
});

export interface CertificateOptions {
  name: string;
  schoolName: string;
  date: Date;
  achievementText: string;
  achievementNumber?: number;
  teachers: string[];

  colours: [string, string];
}

// Create Document Component
export const CertificateGroup = ({ certs }: { certs: CertificateOptions[] }) => {
  return (
    <Document>
      {certs.map((opts, i) => (
        <Certificate opts={opts} key={i} />
      ))}
    </Document>
  );
};

// Create Document Component
export const Certificate = ({ opts }: { opts: CertificateOptions }) => {
  const cornerText = (opts.achievementNumber || 0).toString();
  const tightCornerText = cornerText.length > 2;
  const veryTightCornerText = cornerText.length > 3;

  return (
    <Page size="A4" orientation="landscape" style={styles.page}>
      <View style={styles.borderContainer}>
        <Svg
          style={styles.svgBorderContainer}
          width="100%"
          height="100%"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <Defs>
            <LinearGradient id="myLinearGradient">
              <Stop offset="0%" stopColor={opts.colours[0]} />
              <Stop offset="100%" stopColor={opts.colours[1]} />
            </LinearGradient>
          </Defs>

          <Rect x="0" y="0" width="100%" height="100%" fill="url('#myLinearGradient')" />
          <Rect x="0" y="0" width="100%" height="100%" fill="url('#myLinearGradient')" />
        </Svg>
        <View style={styles.container}>
          <CornerStar
            style={{
              bottom: -120,
              right: veryTightCornerText ? -70 : tightCornerText ? -105 : -120,
            }}
            fill={opts.colours[1]}
          />
          <CornerStar style={{ top: -120, left: -120 }} fill={opts.colours[0]} />

          <Text style={styles.schoolName}>{opts.schoolName}</Text>

          <View style={styles.main}>
            <Text style={styles.presentedTo}>Congratulations to:</Text>
            <Text style={styles.name}>{opts.name}</Text>

            <View style={styles.achievementDescription}>
              <Image src={Badge} style={styles.achievementDescriptionBadge} />
              <Text style={styles.achievementDescriptionText}>
                {opts.achievementText} on{' '}
                <Text style={{ fontWeight: 'bold' }}>{format(opts.date, 'EEEE d MMMM yyyy')}</Text>
              </Text>
            </View>

            <View style={styles.signatures}>
              {opts.teachers.map((teacher, i) => (
                <View key={i} style={styles.signature}>
                  <View style={styles.signatureLine} />
                  <Text style={styles.signatureText}>{teacher}</Text>
                </View>
              ))}
            </View>
          </View>

          <Image src={SparxLogo} style={styles.logo} />

          {opts.achievementNumber && (
            <>
              <View
                style={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  width: 50,
                  height: 70,
                  backgroundColor: opts.colours[1],
                }}
              />
              <View style={styles.cornerText}>
                <Text>{cornerText}</Text>
              </View>
            </>
          )}
        </View>
      </View>
    </Page>
  );
};

const CornerStar = ({ style, fill }: { style: Record<string, number>; fill: string }) => (
  <Svg
    style={{
      position: 'absolute',
      width: 300,
      height: 300,
      ...style,
    }}
    width={300}
    height={300}
    viewBox="0 0 118 118"
  >
    <Path
      d="M37 113.4 c-4.1 -1.8 -7.9 -3.5 -8.3 -3.6 -0.8 -0.3 4.2 -13.6 9.8 -26.2 1.4 -3.2 2.5 -6 2.3 -6.1 -0.2 -0.2 -7.3 2.6 -15.8 6.1 -8.5 3.5 -15.8 6.4 -16.1 6.4 -0.3 0 -2.1 -3.9 -4 -8.8 l-3.5 -8.7 15.3 -6.4 c8.4 -3.5 15.3 -6.7 15.3 -7.1 0 -0.7 -28.4 -13 -30 -13 -0.7 0 5.4 -16.3 6.6 -17.9 0.2 -0.2 2.1 0.3 4.1 1.1 19.6 8.1 28.3 11.6 28.3 11.4 0 -0.4 -5.1 -12.9 -7 -17.2 -2.9 -6.3 -6.1 -14.6 -5.8 -14.9 0.2 -0.2 4.1 -1.9 8.8 -3.8 l8.5 -3.5 6.5 15.4 c3.5 8.5 6.7 15.4 7.1 15.4 0.3 0 3.5 -6.9 6.9 -15.2 3.5 -8.4 6.4 -15.4 6.5 -15.5 0.1 -0.3 16.6 6.6 17.3 7.2 0.3 0.3 -7 18.8 -10.4 26.4 -1.4 2.9 -2.4 5.5 -2.2 5.6 0.2 0.2 7.3 -2.6 15.8 -6.1 8.5 -3.5 15.8 -6.4 16.1 -6.4 0.3 0 2.1 3.9 4 8.8 l3.5 8.7 -15.3 6.4 c-8.4 3.5 -15.3 6.7 -15.3 7.1 0 0.7 28.4 13 30 13 0.7 0 -5.4 16.3 -6.6 17.9 -0.2 0.2 -2.1 -0.3 -4.1 -1.1 -20.7 -8.6 -28.3 -11.6 -28.3 -11.3 0 0.2 2.6 6.6 5.9 14.2 3.2 7.6 6.1 14.7 6.5 15.9 0.5 1.8 -0.4 2.5 -8.1 5.6 l-8.8 3.6 -6.5 -15.4 c-3.5 -8.5 -6.7 -15.4 -7.1 -15.4 -0.4 0 -3.5 7 -7 15.5 -3.5 8.5 -6.6 15.4 -6.9 15.4 -0.3 0 -3.9 -1.6 -8 -3.5z"
      fill={fill}
    />
  </Svg>
);
