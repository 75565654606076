import { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport';
import { RpcInterceptor, RpcTransport, UnaryCall } from '@protobuf-ts/runtime-rpc';
import { SittingsClient } from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting.client';
import { UserProductDataServiceClient } from '@sparx/api/apis/sparx/landing/v1/landing.client';
import { ReportingClient } from '@sparx/api/apis/sparx/reading/bookmark/reporting/v1/reporting.client';
import { SchoolsServiceClient } from '@sparx/api/apis/sparx/school/v2/schools.client';
import { GroupsAPIClient } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi.client';
import { StudentAPIClient } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi.client';
import { AccessTokenProvider, extensionInterceptor } from '@sparx/grpcweb';
import queryString from 'query-string';

const getSchoolId = (school: string | (string | null)[] | null | undefined) => {
  return (school ? school.toString() : '').replace('schools/', '');
};

export const getSchoolIDFromUrl = () => {
  const { school } = queryString.parse(window.location.search);
  return getSchoolId(school);
};

export const redirectToLogin = async () => {
  const { school, selectschool, is, fg } = queryString.parse(window.location.search);
  const schoolID = getSchoolId(school);
  const shouldSelectSchool = Boolean(selectschool?.toString());
  const route = window.location.toString();
  const isQuery = is?.toString() ? { is: 'true' } : {};
  const fgQuery = fg?.toString() ? { fg: '1' } : {};
  if (!schoolID || shouldSelectSchool) {
    const query = queryString.stringify({
      app: 'sparx_primary',
      route,
      ...(shouldSelectSchool ? { forget: 1 } : {}),
    });
    window.location.replace(window.settings?.selectSchoolUrl + '?' + query);
  } else {
    const query = queryString.stringify({ school: schoolID, route, ...isQuery, ...fgQuery });
    window.location.replace(window.settings?.authUrl + '/oauth2/login?' + query);
  }
  return new Promise(() => {
    /*pass*/
  }); // never return
};

export const logout = async () =>
  fetch(window.settings?.authUrl + '/oauth2/logout', {
    credentials: 'include',
  }).then(() => {
    redirectToLogin();
    return new Promise<Response>(() => {
      /*pass*/
    }); // never return
  });

let tokenFetcherStarted = false;

export const tokenFetcher = () =>
  fetch(window.settings?.authUrl + '/token', {
    method: 'GET',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      // 'X-CSRF-Token': getCsrfToken(), // TODO?
    },
  }).then(async resp => {
    if (!resp.ok && resp.status === 401) {
      // Unauthorised - redirect to login page
      if (tokenFetcherStarted) {
        await redirectToLogin();
      } else {
        throw new Error('Unauthorised');
      }
    }
    return resp;
  });

// Configure the access token provider and set it to load periodically
const accessTokenProvider: AccessTokenProvider = new AccessTokenProvider(tokenFetcher);
export const ensureTokenFetcherStarted = () => {
  if (!tokenFetcherStarted) {
    tokenFetcherStarted = true;
    accessTokenProvider.start().then(() => console.log('Access Token Provider initialised'));
  }
};

export const requestAccessToken = () => accessTokenProvider.requestAccessToken();

interface iToken {
  sub?: string;
  sch?: string;
  spx?: {
    sch?: string;
  };
}

type Token = {
  subject?: string;
  school?: string;
};

export const parseToken = (token: string): Token => {
  token = token.replace('bearer ', '');
  const parts = token.split('.');
  const payload = JSON.parse(atob(parts[1]));
  const itok = payload as iToken;
  return {
    subject: itok.sub,
    school: itok.spx?.sch ?? itok.sch,
  };
};

export const getSchoolIdFromSession = async () => {
  const accessToken = await requestAccessToken();
  const token = parseToken(accessToken);
  return token.school;
};

// This is copied from the @sparx/grpc package but removes the streaming interceptor
const authInterceptor = (accessTokenProvider: AccessTokenProvider): RpcInterceptor => ({
  // Based on approach here: https://github.com/timostamm/protobuf-ts/issues/31#issuecomment-733025632
  interceptUnary(next, method, input, options): UnaryCall {
    const callPromise = new Promise<UnaryCall>(resolve => {
      accessTokenProvider.requestAccessToken().then(accessToken => {
        if (!options.meta) options.meta = {};
        options.meta['Authorization'] = accessToken;
        resolve(next(method, input, options));
      });
    });

    return new UnaryCall(
      method,
      options.meta ?? {},
      input,
      callPromise.then(c => c.headers),
      callPromise.then(c => c.response),
      callPromise.then(c => c.status),
      callPromise.then(c => c.trailers),
    );
  },
});

interface TransportOptions {
  keepalive?: boolean;
  noAuthInterceptor?: boolean;
}

export const getTransport = (url: string, options?: TransportOptions): RpcTransport =>
  new GrpcWebFetchTransport({
    baseUrl: url,
    format: 'binary',
    fetchInit: {
      credentials: 'include',
      keepalive: options?.keepalive || false, // Try turning off (TODO: reconsider whether this had an impact)
    },
    interceptors: [
      ...(!options?.noAuthInterceptor ? [authInterceptor(accessTokenProvider)] : []),
      extensionInterceptor,
    ],
  });

export const userProductDataService = new UserProductDataServiceClient(
  getTransport(window.settings?.authUrl || ''),
);

export const schoolsClient = new SchoolsServiceClient(getTransport(window.settings?.apiUrl || ''));
export const studentClient = new StudentAPIClient(getTransport(window.settings?.apiUrl || ''));
export const groupsClient = new GroupsAPIClient(getTransport(window.settings?.apiUrl || ''));
export const reportingClient = new ReportingClient(
  getTransport((window.settings?.apiUrl || '') + '/bookmark'),
);
export const assessmentsSittingClient = new SittingsClient(
  getTransport((window.settings?.apiUrl || '') + '/assessments'),
);
