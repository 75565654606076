import { SparxQueryProvider as SpxQuery } from '@sparx/query';
import { setSchoolsClient } from '@sparx/query/schools-service';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import { getSchoolIdFromSession, schoolsClient } from 'api/auth';
import { PropsWithChildren } from 'react';

const queryClient = new QueryClient();

// Register the clients with @sparx/query
setSchoolsClient(schoolsClient);

export const QueryProvider = ({ children }: PropsWithChildren) => (
  <QueryClientProvider client={queryClient}>
    <SparxQueryProvider>{children}</SparxQueryProvider>
  </QueryClientProvider>
);

const SparxQueryProvider = ({ children }: PropsWithChildren) => {
  const { data: schoolName } = useQuery({
    queryKey: ['school', 'name'],
    queryFn: async () => {
      const schoolID = await getSchoolIdFromSession();
      return `schools/${schoolID}`;
    },
    cacheTime: Infinity,
    staleTime: Infinity,
    suspense: true,
  });

  return <SpxQuery schoolName={schoolName || ''}>{children}</SpxQuery>;
};
