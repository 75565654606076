import { RpcError } from '@protobuf-ts/runtime-rpc';
import * as Sentry from '@sentry/react';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { NotFound, UnknownError } from 'app/ErrorPages';
import { LargeLoading } from 'components/largeloading/LargeLoading';
import React, { Suspense } from 'react';

export const SuspenseRoute = ({ children }: { children: React.ReactNode }) => (
  <Suspense fallback={<LargeLoading />}>
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <Sentry.ErrorBoundary
          onReset={reset}
          fallback={({ resetError, error }) => <ErrorHandler error={error} reset={resetError} />}
        >
          {children}
        </Sentry.ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  </Suspense>
);

const ErrorHandler = ({ error, reset }: { error: Error; reset: () => void }) => {
  const rpcError = error instanceof RpcError ? error : undefined;
  if (rpcError?.code === 'NOT_FOUND' || rpcError?.code === 'INVALID_ARGUMENT') {
    return <NotFound />;
  }
  if (
    rpcError?.code === 'PERMISSION_DENIED' ||
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed.')
  ) {
    // For permission denied errors make reset refresh the page, as this should
    // force us to get a new token too
    // Also force refresh for module import errors as that should fetch the latest index file
    reset = () => location.reload();
  }
  return <UnknownError reset={reset} />;
};
