import { useSessionStorage } from '@sparx/react-utils';
import { Select, SelectItem } from 'components/select/Select';
import { ReportingPeriod } from 'utils/period';

export const useReportingToggle = () => {
  const [value, setValue] = useSessionStorage('pri/reporting-period', 'thisweek');

  return {
    component: <ReportingToggle value={value as ReportingPeriod} setValue={setValue} />,
    value: value as ReportingPeriod,
  };
};

const ReportingToggle = ({
  value,
  setValue,
}: {
  value: ReportingPeriod;
  setValue: (value: ReportingPeriod) => void;
}) => {
  return (
    <Select value={value} onValueChange={setValue}>
      <SelectItem value={'thisweek'}>This Week</SelectItem>
      <SelectItem value={'lastweek'}>Last Week</SelectItem>
      <SelectItem value={'thisyear'}>This Year</SelectItem>
    </Select>
  );
};
