import { Book } from '@sparx/api/apis/sparx/reading/books/v1/book';

export const getImageURL = (bookName: string, image: string): string =>
  `${window.settings?.readerApiUrl}/images/v2/${tidyBookName(bookName)}/${tidyAssetName(image)}`;

const tidyBookName = (bookName: string): string => bookName.replace('books/', '');

const tidyAssetName = (assetName: string): string => {
  const split = assetName.split('/');
  // Expect "books/{book_id}/asset/{asset_id}"
  if (split.length !== 4) {
    console.warn('assetName incorrectly formatted', assetName);
    return assetName;
  }
  return split[split.length - 1]; // return final component (asset id)
};

export const getBookCoverUrl = (book: Book): string => {
  const imageSource = book.coverImage?.source;
  if (imageSource?.oneofKind !== 'name') {
    return '';
  }
  const bookName = book.name;
  const assetName = imageSource.name;

  return getImageURL(bookName, assetName);
};
