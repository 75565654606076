import { School } from '@sparx/api/apis/sparx/school/v2/schools';
import { FetchQueryOptions, useQuery } from '@tanstack/react-query';
import { getSchoolIdFromSession, reportingClient, schoolsClient } from 'api/auth';

const schoolQuery: FetchQueryOptions<School> = {
  queryKey: ['school', 'data'],
  queryFn: async () =>
    schoolsClient.getSchool({
      name: 'schools/' + (await getSchoolIdFromSession()),
    }).response,
  cacheTime: Infinity,
  staleTime: Infinity,
};

export const useSchool = (opts?: { suspense?: boolean }) =>
  useQuery({
    ...schoolQuery,
    ...opts,
  });

export const useBookmarkSchool = (opts?: { suspense?: boolean }) =>
  useQuery({
    queryKey: ['bookmark', 'school'],
    queryFn: async () =>
      reportingClient.getSchoolSettings({
        schoolName: `schools/${await getSchoolIdFromSession()}`,
      }).response,
    cacheTime: Infinity,
    staleTime: Infinity,
    ...opts,
  });
