import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faCaretDown,
  faCheck,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as RadixSelect from '@radix-ui/react-select';
import { ComponentProps, PropsWithChildren } from 'react';

import styles from './Select.module.css';

interface Props extends ComponentProps<typeof RadixSelect.Root> {}

export const Select = ({ children, ...props }: Props) => (
  <RadixSelect.Root {...props}>
    <RadixSelect.Trigger className={styles.FilterButton}>
      <RadixSelect.Value placeholder="SORT" />
      <FontAwesomeIcon icon={faCaretDown} />
    </RadixSelect.Trigger>
    <RadixSelect.Portal>
      <RadixSelect.Content className={styles.Dropdown}>
        <ScrollUpButton />
        <RadixSelect.Viewport>{children}</RadixSelect.Viewport>
        <ScrollDownButton />
      </RadixSelect.Content>
    </RadixSelect.Portal>
  </RadixSelect.Root>
);

const ScrollUpButton = () => (
  <RadixSelect.ScrollUpButton className={styles.ScrollButton}>
    <FontAwesomeIcon icon={faChevronUp} />
  </RadixSelect.ScrollUpButton>
);

const ScrollDownButton = () => (
  <RadixSelect.ScrollDownButton className={styles.ScrollButton}>
    <FontAwesomeIcon icon={faChevronDown} />
  </RadixSelect.ScrollDownButton>
);

export const SelectItem = ({
  value,
  children,
  icon,
}: PropsWithChildren<{ value: string; icon?: IconDefinition }>) => (
  <RadixSelect.Item value={value} className={styles.DropdownItem}>
    <RadixSelect.ItemText>{children}</RadixSelect.ItemText>
    <RadixSelect.ItemIndicator className={styles.DropdownIndicator}>
      <FontAwesomeIcon icon={icon || faCheck} />
    </RadixSelect.ItemIndicator>
  </RadixSelect.Item>
);
