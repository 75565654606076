import { Timestamp as pbTimestamp } from '@sparx/api/google/protobuf/timestamp';
import { format } from 'date-fns';
import { useMemo } from 'react';

interface TimestampProps {
  children: pbTimestamp | Date | undefined;
  fmt?: string;
}

export const PrettyTimestamp = ({ children, fmt = 'p EEEE do MMMM' }: TimestampProps) => {
  const date = useMemo(
    () => children && (children instanceof Date ? children : pbTimestamp.toDate(children)),
    [children],
  );
  return <>{date ? format(date, fmt) : ''}</>;
};
