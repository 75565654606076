import { faCheck, faShuffle, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ListUserBooksResponse_UserBook,
  UserBookLog,
} from '@sparx/api/apis/sparx/reading/bookmark/reporting/v1/reporting';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { NotFound } from 'app/ErrorPages';
import { PageHeader } from 'components/pageheader/PageHeader';
import { PageContainer } from 'components/pages/PageContainer';
import { PrettyTimestamp } from 'components/timestamp/PrettyTimestamp';
import { useBookMetadataSuspense } from 'queries/content';
import { useUserBookLogs, useUserBooks } from 'queries/reporting';
import { useStudents } from 'queries/students';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useUserUserBooks } from 'utils/books';
import { getBookCoverUrl } from 'utils/image';

import styles from './StudentSummary.module.css';

export const StudentSummary = () => {
  const { data: logs } = useUserBookLogs({ suspense: true });
  const { data: students = [] } = useStudents({ suspense: true });
  const { data: books } = useUserBooks({ suspense: true });

  const { studentID = '' } = useParams();
  const student = students.find(s => s.studentId == studentID);

  const userBooks = useUserUserBooks(books?.userBooks || [], studentID);

  const studentLogs = useMemo(
    () =>
      logs?.userBookLogs
        .filter(log => log.userId == studentID)
        .sort((a, b) => (b.timestamp?.seconds || 0) - (a.timestamp?.seconds || 0)),
    [logs, studentID],
  );

  if (!student) {
    return <NotFound />;
  }

  return <Summary student={student} logs={studentLogs || []} userBooks={userBooks} />;
};

interface SummaryProps {
  student: Student;
  logs: UserBookLog[];
  userBooks: ListUserBooksResponse_UserBook[];
}

const Summary = ({ student, logs, userBooks }: SummaryProps) => {
  const recentBooks = useMemo(
    () =>
      userBooks
        .sort(
          // Sort by most recently read
          (a, b) =>
            (b.userBook?.lastReadTimestamp?.seconds || 0) -
            (a.userBook?.lastReadTimestamp?.seconds || 0),
        )
        .slice(0, 4),
    [userBooks],
  );

  return (
    <PageContainer>
      <PageHeader>
        {student.givenName} {student.familyName}
      </PageHeader>

      <div className={styles.Block}>
        <h3>Recent books</h3>
        <div className={styles.Books}>
          {recentBooks.map(userBook => {
            if (!userBook.userBook) return null; // ignore

            return (
              <div key={userBook.userBook.bookName} className={styles.Book}>
                <BookHeader name={userBook.userBook.bookName} />

                <div className={styles.BookStats}>
                  <div className={styles.BookStat}>
                    {userBook.userBook?.swappedTimestamp ? (
                      <span className={styles.BookStatSwapped}>
                        <FontAwesomeIcon icon={faShuffle} />
                        Swapped
                      </span>
                    ) : (
                      <>
                        {userBook.userBook?.finishedTimestamp && (
                          <FontAwesomeIcon icon={faCheck} className={styles.BookStatComplete} />
                        )}
                        <span className={styles.BookStatProgress}>
                          {Math.round(userBook.userBook.progress * 100)}%
                        </span>
                      </>
                    )}
                  </div>
                  {!!userBook.userBook?.rating?.enjoymentRating && (
                    <div className={styles.BookStat}>
                      <FontAwesomeIcon icon={faStar} className={styles.BookStatStar} />
                      <span className={styles.BookStatRating}>
                        {userBook.userBook?.rating?.enjoymentRating / 2}
                      </span>
                      <span className={styles.BookStatRatingLabel}>/ 5</span>
                    </div>
                  )}
                  {userBook?.userBook?.state?.config?.hasConfig && (
                    <div className={styles.BookStat}>
                      <span className={styles.BookStatPages}>
                        {userBook.userBook.state.config.endPage}
                      </span>
                      <span className={styles.BookStatPageCountLabel}>pages</span>
                    </div>
                  )}
                </div>

                {userBook.userBook?.lastReadTimestamp && (
                  <span className={styles.LastRead}>
                    Last read:{' '}
                    <PrettyTimestamp>{userBook.userBook?.lastReadTimestamp}</PrettyTimestamp>
                  </span>
                )}
              </div>
            );
          })}
        </div>
      </div>

      <div className={styles.Block}>
        <h3>Recent logs</h3>
        <ul className={styles.Logs}>
          {logs.map(log => (
            <li key={log.userBookLogId}>
              <div className={styles.LogBook}>
                <div className={styles.LogBookHeader}>
                  <BookLogHeader name={log.bookName} />
                  <PrettyTimestamp>{log.timestamp}</PrettyTimestamp>
                </div>
                <div className={styles.LogBookContent}>
                  <span>
                    Logged pages: {log.state?.startPage} - {log.state?.endPage}
                  </span>
                  <span className={styles.LogBookProgress}>
                    {Math.round(log.progress * 100)}% complete
                  </span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </PageContainer>
  );
};

const BookHeader = ({ name }: { name: string }) => {
  const { data: book } = useBookMetadataSuspense(name);
  if (!book) {
    return <span className={styles.BookTitle}>Unknown book</span>;
  }

  return (
    <>
      <img alt="" src={getBookCoverUrl(book)} />
      <span className={styles.BookTitle}>{book.title}</span>
      <span className={styles.BookAuthors}>{book.authors.join(', ')}</span>
    </>
  );
};

const BookLogHeader = ({ name }: { name: string }) => {
  const { data: book } = useBookMetadataSuspense(name);
  if (!book) {
    return <span className={styles.BookTitle}>Unknown book</span>;
  }

  return (
    <>
      <img alt="" src={getBookCoverUrl(book)} />
      <span>{book.title}</span>
    </>
  );
};
