import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';
import { PropsWithChildren } from 'react';

import styles from './LargeLoading.module.css';

export const LargeLoading = ({ children }: PropsWithChildren) => (
  <div className={styles.LargeLoading}>
    <LoadingSpinner size="lg" />
    {children && <div className={styles.Message}>{children}</div>}
  </div>
);
