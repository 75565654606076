import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserBookmark } from '@sparx/api/apis/sparx/reading/bookmark/reporting/v1/reporting';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { Button } from '@sparx/design/components';
import { Stack } from '@sparx/sparx-design/components';
import { Alert } from '@sparx/sparx-design/components/alert/Alert';
import { useQuery } from '@tanstack/react-query';
import { useClassSelectionStudents } from 'components/header/NavigationControllerProvider';
import { LargeLoading } from 'components/largeloading/LargeLoading';
import { PageHeader, PageHeaderSubpage } from 'components/pageheader/PageHeader';
import { PageContainer } from 'components/pages/PageContainer';
import { useUserBookmarks } from 'queries/reporting';
import { useMemo, useState } from 'react';
import * as uuid from 'uuid';

import styles from './BookmarkView.module.css';

export const BookmarkView = () => {
  const { students, selectedGroup } = useClassSelectionStudents();

  if (selectedGroup?.type !== 'group') {
    return <></>;
  }

  return <Page students={students} group={selectedGroup.group} />;
};

const openBookmarksInNewWindow = () => {
  const externalWindow = window.open('', '');
  const headElement = document.querySelector('#bookmarks');
  if (externalWindow && headElement) {
    externalWindow.document.body.appendChild(headElement.cloneNode(true));
    document.head.querySelectorAll('link, style').forEach(htmlElement => {
      externalWindow.document.head.appendChild(htmlElement.cloneNode(true));
    });

    const style = externalWindow.document.createElement('style');
    style.innerHTML = `body{position:relative !important;overflow: auto !important;}html{position:relative}`;
    externalWindow.document.head.append(style);
    externalWindow.document.body.className = 'printable';
    externalWindow.document.title = 'Bookmarks';
    externalWindow.onafterprint = () => externalWindow.close();
    externalWindow.print();
  }
};

const Page = ({ students, group }: { students: Student[]; group: Group }) => {
  const subjects = useMemo(() => students.map(s => `student/${s.studentId}`), [students]);
  const { data: bookmarks, isLoading } = useUserBookmarks(subjects, { suspense: false });

  const studentLookup = useMemo(() => {
    const lookup: Record<string, Student> = {};
    students.forEach(s => (lookup[s.studentId] = s));
    return lookup;
  }, [students]);

  const bookmarksWithStudents = useMemo(
    () =>
      bookmarks?.userBookmarks
        .map(b => ({
          bookmark: b,
          student: studentLookup[b.userId],
        }))
        .filter(b => !!b.student)
        .sort(
          (a, b) =>
            a.student.givenName.localeCompare(b.student.givenName) ||
            a.student.familyName.localeCompare(b.student.familyName),
        ) || [],
    [bookmarks, studentLookup],
  );

  return (
    <PageContainer>
      <PageHeader title={`${group.displayName} - Bookmarks`}>
        Bookmarks
        <PageHeaderSubpage>{group.displayName}</PageHeaderSubpage>
      </PageHeader>

      <Alert>
        <Alert.Icon />
        <Alert.Description>
          Use this page to access a list of bookmarks for your students.
        </Alert.Description>
      </Alert>

      {isLoading ? (
        <LargeLoading>Loading bookmarks...</LargeLoading>
      ) : (
        <Stack direction="column" spacing={4} className={styles.Content}>
          <div>
            <Button
              onClick={openBookmarksInNewWindow}
              leftIcon={<FontAwesomeIcon icon={faPrint} />}
            >
              Print bookmarks for {students.length} students
            </Button>
          </div>
          <div className={styles.Bookmarks} id="bookmarks">
            {bookmarksWithStudents.map(bk => (
              <BookmarkDisplay
                bookmark={bk.bookmark}
                student={bk.student}
                key={bk.student.studentId}
              />
            ))}
          </div>
        </Stack>
      )}
    </PageContainer>
  );
};

const BookmarkDisplay = ({ bookmark, student }: { bookmark: UserBookmark; student: Student }) => {
  const blob = useMemo(() => new Blob([bookmark.image], { type: 'image/png' }), [bookmark]);

  return (
    <div className={styles.Bookmark}>
      <span className={styles.BookmarkName}>
        {student.givenName} {student.familyName}
      </span>
      <ScaledBookmarkImage blob={blob} />
    </div>
  );
};

const imageToDataUri = async (blob: Blob, width: number, height: number) => {
  // create an off-screen canvas
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('Could not get 2d context');
  }

  ctx.imageSmoothingEnabled = false;
  canvas.width = width;
  canvas.height = height;

  const image = await createImageBitmap(blob, {
    resizeQuality: 'pixelated',
    resizeHeight: height,
    resizeWidth: width,
  });
  ctx.drawImage(image, 0, 0, width, height);

  return canvas.toDataURL();
};

const ScaledBookmarkImage = ({ blob }: { blob: Blob }) => {
  const [id] = useState(uuid.v4());
  const { data } = useQuery({
    queryKey: ['image', id],
    queryFn: () => imageToDataUri(blob, 200, 200),
    staleTime: Infinity,
    cacheTime: 0,
  });
  return <img src={data} alt="" />;
};
