import { NotFound } from 'app/ErrorPages';
import { useIsSparxStaff } from 'queries/sessions';
import { PropsWithChildren } from 'react';

export const SparxStaffView = ({ children }: PropsWithChildren) => {
  const isSparxStaff = useIsSparxStaff({ suspense: true });
  if (!isSparxStaff) {
    return <NotFound />;
  }
  return children;
};
