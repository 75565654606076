import { RpcError } from '@protobuf-ts/runtime-rpc';
import {
  Group,
  ListGroupsRequest,
  ListGroupsResponse,
} from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getSchoolIdFromSession, groupsClient } from 'api/auth';

export const useGroups = (opts?: UseQueryOptions<ListGroupsResponse, RpcError, Group[]>) =>
  useQuery({
    queryKey: ['groups'],
    queryFn: async () =>
      groupsClient.listGroups(
        ListGroupsRequest.create({
          parent: `schools/${await getSchoolIdFromSession()}`,
        }),
      ).response,
    select: data => data.groups,
    staleTime: 10 * 60 * 1000, // 10 minutes
    ...opts,
  });
