import { faCheck, faCopy, faShuffle, faSignOut } from '@fortawesome/free-solid-svg-icons';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { LoadingSpinner } from '@sparx/design/components';
import { getSchool } from '@sparx/query/schools-service';
import { Switch } from '@sparx/sparx-design/components/switch/Switch';
import Logo from '@sparx/sparx-design/logos/sparx_primary_logo.svg';
import { logout } from 'api/auth';
import { ClassSelect } from 'components/header/ClassSelect';
import {
  DropdownMenuContainer,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownSeparator,
  MenuButton,
} from 'components/to-migrate/menu/Menu';
import { useIsActuallySparxStaff, useSession, useToggleSparxStaffFeatures } from 'queries/sessions';
import React, { Suspense, useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './Header.module.css';

export const Header = () => {
  const { data: session } = useSession({ suspense: true });
  const { data: school } = getSchool.useSuspenseQuery();

  const isSparxStaff = useIsActuallySparxStaff({ suspense: true });
  const [sparxStaffFeaturesEnabled, toggleSparxStaffFeaturesEnabled] =
    useToggleSparxStaffFeatures();

  const searchParams = window?.location.search;
  const schoolParam = (searchParams ? '&' : '?') + 'school=' + session?.schoolID;
  const [copied, setCopied] = useState(false);
  const handleCopy: React.MouseEventHandler<HTMLDivElement> = evt => {
    // Prevent menu from closing when clicking copy.
    evt.stopPropagation();
    evt.preventDefault();
    // eslint-disable-next-line compat/compat -- admin only feature
    navigator.clipboard.writeText(window.location.href + schoolParam);
    // Change the button text to indicate success
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  return (
    <header className={styles.Header}>
      {/*<BackLink />*/}
      <Link to="/teacher">
        <h1 className={styles.Logo}>
          <img src={Logo} alt="Sparx" />
        </h1>
      </Link>
      <Suspense fallback={<LoadingSpinner />}>
        <ClassSelect />
      </Suspense>
      <div className={styles.Spacer} />
      <div className={styles.Actions}>
        <DropdownMenu.Root modal={false}>
          <DropdownMenu.Trigger asChild>
            <MenuButton>{session?.displayName}</MenuButton>
          </DropdownMenu.Trigger>
          <DropdownMenuContent align="end">
            <div className={styles.CurrentSchoolBox}>
              <span>Signed into:</span>
              <h2>{school?.displayName}</h2>
            </div>
            <DropdownSeparator />
            {isSparxStaff && (
              <>
                <DropdownMenuContainer className={styles.SparxFeaturesContainer}>
                  <span>Sparx Staff Features</span>
                  <Switch
                    id="sparxStaffFeaturesToggle"
                    labelLeft="Off"
                    labelRight="On"
                    checked={sparxStaffFeaturesEnabled}
                    onCheckedChange={toggleSparxStaffFeaturesEnabled}
                  />
                </DropdownMenuContainer>
                <DropdownMenuItem
                  icon={faShuffle}
                  onClick={() => {
                    window.location.href = `${window.settings?.selectSchoolUrl}/?app=sparx_primary&noredirect=1&route=${encodeURIComponent(window.location.origin)}`;
                  }}
                >
                  Switch school
                </DropdownMenuItem>
                <DropdownMenuItem icon={copied ? faCheck : faCopy} onClick={handleCopy}>
                  Copy URL
                </DropdownMenuItem>
                <DropdownSeparator />
              </>
            )}
            <DropdownMenuItem icon={faSignOut} onClick={() => logout()}>
              Sign out
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu.Root>
      </div>
    </header>
  );
};
