import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UploadedAssessmentResult } from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { createColumnHelper } from '@tanstack/react-table';
import { useClassSelectionStudents } from 'components/header/NavigationControllerProvider';
import { PageHeader, PageHeaderSubpage } from 'components/pageheader/PageHeader';
import { PageContainer } from 'components/pages/PageContainer';
import { useReportingToggle } from 'components/reportingToggle';
import { DataTable } from 'components/table/DataTable';
import { PrettyTimestamp } from 'components/timestamp/PrettyTimestamp';
import { useSittingUploadedResultsMap } from 'queries/assessments';
import { useUserBookLogs, useUserBooks } from 'queries/reporting';
import { ReactNode, useMemo } from 'react';
import { useReportingPeriod } from 'utils/period';
import { StudentPages, useBooklogPageStats, useLogsBetweenReportingPeriod } from 'utils/stats';

import styles from './ReportingView.module.css';

interface Row {
  student: Student;
  displayName: string;
  pages: StudentPages;
  readingAgeMonths?: number;
}

// Primary assessment name (secondary is 0e639198-3a16-11ef-8367-efdfd58def54)
const assessmentName = 'assessments/fa7d2420-6b78-11ef-834a-d3a68f240dc5';

const READING_AGE_MONTHS_ANNOTATION = 'assessment/reading-age-months';

const loadReadingAgeMonthsFromUploadedResults = (uploadedResults?: UploadedAssessmentResult[]) => {
  const latestResult = uploadedResults?.sort(
    (a, b) => (a.createdTimestamp?.seconds || 0) - (b.createdTimestamp?.seconds || 0),
  )?.[0];

  console.log(latestResult);
  if (latestResult) {
    try {
      const readingAge = latestResult.package?.annotations?.[READING_AGE_MONTHS_ANNOTATION];
      if (readingAge) {
        return parseInt(readingAge || '0');
      }
    } catch (e) {
      console.error('Failed to parse reading age', e);
    }
  }
  return undefined;
};

export const ReportingView = () => {
  const { data: logs } = useUserBookLogs({ suspense: true });
  const { data: books } = useUserBooks({ suspense: true });

  const { component, value: period } = useReportingToggle();
  const { start, end } = useReportingPeriod(period);
  const reportingLogs = useLogsBetweenReportingPeriod(logs?.userBookLogs, start, end);

  const stats = useBooklogPageStats(reportingLogs, books?.userBooks);

  const { students, selectedGroup } = useClassSelectionStudents();
  const { data: uploadedResults } = useSittingUploadedResultsMap(
    assessmentName,
    students.map(s => `students/${s.studentId}`),
    { suspense: true },
  );

  const rows: Row[] = useMemo(
    () =>
      students.map(student => {
        const readingAgeResults = uploadedResults?.get(student.studentId);
        const readingAgeMonths = loadReadingAgeMonthsFromUploadedResults(readingAgeResults);

        return {
          student,
          displayName: `${student.givenName} ${student.familyName}`,
          pages: stats[student.studentId] ?? { totalWords: 0, totalPages: 0, numberLogs: 0 },
          readingAgeMonths,
        };
      }),
    [students, stats, uploadedResults],
  );

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<Row>();
    return [
      columnHelper.accessor(`displayName`, {
        header: 'Name',
        sortingFn: (a, b) =>
          // Sort by surname then first name
          a.original.student.familyName.localeCompare(b.original.student.familyName) ||
          a.original.student.givenName.localeCompare(b.original.student.givenName) ||
          a.original.student.studentId.localeCompare(b.original.student.studentId), // stability
        enableSorting: true,
        meta: {
          linkTo: (row: Row) => ({ to: `/teacher/student/${row.student.studentId}/logs` }),
        },
      }),
      columnHelper.accessor(`readingAgeMonths`, {
        header: 'Reading Age',
        cell: ({ getValue }) => monthsToDisplay(getValue()),
        meta: {
          width: '18%',
        },
      }),
      columnHelper.accessor(`pages.numberLogs`, {
        header: 'Number of logs',
        cell: ({ getValue }) => <FormattedValue>{getValue()}</FormattedValue>,
        meta: {
          width: '18%',
        },
      }),
      columnHelper.accessor(`pages.totalPages`, {
        header: 'Pages Read',
        cell: ({ getValue, row }) => (
          <>
            <FormattedValue>{getValue()}</FormattedValue>
            {row.original.pages.pagesReadSus && (
              <FontAwesomeIcon icon={faWarning} className={styles.Warning} />
            )}
          </>
        ),
        meta: {
          width: '18%',
        },
      }),
      columnHelper.accessor(`pages.totalWords`, {
        header: 'Words Read',
        cell: ({ getValue, row }) => (
          <FormattedValue prefix={row.original.pages.wordsReadIncomplete ? '~' : ''}>
            {getValue()}
          </FormattedValue>
        ),
        meta: {
          width: '18%',
        },
      }),
    ];
  }, []);

  let right;
  if (component) {
    right = (
      <span className={styles.ReportingPeriod}>
        <span>
          <PrettyTimestamp fmt={period === 'thisyear' ? 'do MMM yyyy' : 'p EEE do MMM'}>
            {start}
          </PrettyTimestamp>
        </span>
        -
        <span>
          <PrettyTimestamp fmt={period === 'thisyear' ? 'do MMM yyyy' : 'p EEE do MMM'}>
            {end}
          </PrettyTimestamp>
        </span>
        {component}
      </span>
    );
  }

  if (selectedGroup?.type !== 'group') {
    return <></>;
  }

  return (
    <PageContainer>
      <PageHeader title={`${selectedGroup.group.displayName} - Reporting`} right={right}>
        Reporting
        <PageHeaderSubpage>{selectedGroup.group.displayName}</PageHeaderSubpage>
      </PageHeader>
      <DataTable data={rows} columns={columns} defaultSort={[{ id: 'displayName', desc: false }]} />
    </PageContainer>
  );
};

const FormattedValue = ({
  children,
  prefix,
}: {
  children: number | undefined;
  prefix?: ReactNode;
}) => {
  const display = children?.toLocaleString() ?? 0;
  return (
    <span className={!children ? styles.NoValue : undefined}>
      {prefix}
      {display}
    </span>
  );
};

const monthsToDisplay = (months: number | undefined) => {
  if (!months) return '-';

  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;
  return `${years}:${remainingMonths < 10 ? '0' : ''}${remainingMonths}`;
};
